<template>
  <div class="column right-block small-block">
    <div class="green-block">
      <h3>Daarom bij ons!</h3>
      <p>Waarom hosting van Hosting on Demand?</p>
      <ul>
        <li>Vanuit portaal toegang tot al uw accounts, zonder nog eens in te hoeven loggen</li>
        <li>Domeinen, hosting en SSL-certificaten onder 1 dak</li>
        <li>Uitstekende en snelle support</li>
        <li>Optimale veiligheid van uw gegevens</li>
      </ul>
    </div>
    <div class="green-block">
      <h3>Veel gestelde vragen over domeinnamen</h3>
      <ExpansionPanels
        :panels="questions.map(q => q.title)"
      >
        <template
          v-for="(question, index) in questions"
          :key="index"
          v-slot:[questionSlotName(index)]
        >
          <div v-html="question.content"></div>
        </template>
      </ExpansionPanels>
    </div>
    <div class="green-block">
      <h3>Meer informatie over domeinnamen</h3>
      <ul>
        <li><router-link to="/webhosting/webhosting-small/">Webhosting Small: goedkope shared hosting</router-link></li>
        <li><router-link to="/webhosting/webhosting-large-2/">Webhosting Medium: hosting voor WordPress</router-link></li>
        <li><router-link to="/webhosting/webhosting-large/">Webhosting Large: hosting voor WordPress</router-link></li>
        <li><router-link to="/webhosting-extra-large/">Webhosting Extra Large: professionele hosting</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

import ExpansionPanels from '@/components/ExpansionPanels.vue';

export default {
  name: 'WebhostingRightSideBlocks',
  components: {
    ExpansionPanels,
  },
  setup() {
    const data = inject('data');
    const questions = data.staticData.questions.filter(q => q.tags.split(' ').includes('webhosting'));

    function questionSlotName(index) {
      return `content-text-${index}`;
    }

    return {
      // static data
      questions,

      // methods
      questionSlotName,
    };
  },
}
</script>