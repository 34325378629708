<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>Webhosting Medium: hosting voor WordPress</h1>
        <Price :item="data.staticData.prices.webhostingPrices[1]"/>
        <p>Webhosting Medium is geschikt voor gemiddelde tot grote websites, bijvoorbeeld op basis van een CMS zoals WordPress. Dankzij voldoende webruimte, tientallen e-mailadressen en een groot aantal MySQL-databases biedt dit pakket voor webhosting professionele mogelijkheden.</p>
        <p><i>“Heel tevreden met Webhosting Medium. We vroegen Hosting on Demand om advies en zijn daar erg over te spreken. Het juiste pakket voor onze website, waarmee we uitstekend kunnen bouwen aan een groter online bereik,”</i> aldus een tevreden klant met een website in WordPress.</p>
        <p>Met Webhosting Medium kunt u een website in WordPress hosten, net als iedere andere website. U heeft naast de bovengenoemde eigenschappen de beschikking over 15GB aan dataverkeer en u kunt tot maximaal 15 subdomeinen vastleggen. Verder zijn er geavanceerde statistieken beschikbaar, net als een goede virusscanner en een waterdicht spamfilter.</p>
        <h2>WordPress hosting</h2>
        <p>Webhosting Medium is geschikt voor de hosting van ieder type website, waaronder een website op basis van WordPress. Ook met de andere CMS-systemen kunt u hier uitstekend gebruik van maken. We zorgen er hiermee voor dat u kunt profiteren van geschikte WordPress hosting en het ideale pakket voor andere websites met een vergelijkbare opbouw.</p>
        <h3>Uw voordelen bij Webhosting Medium:</h3>
        <ul>
          <li>6GB webruimte en 17GB dataverkeer</li>
          <li>Virusscanner en spamfilter</li>
          <li>Uitstekende technische begeleiding</li>
        </ul>
        <p>De goede hosting van de website op basis van Webhosting Large zorgt ervoor dat uw website uitstekend bereikbaar is voor al uw bezoekers. <router-link to="/">Registreer een domeinnaam</router-link> en neem webhosting af, zodat u het pakket compleet heeft om goed van start te gaan.</p>
        <p>Let op: naast de pakketten voor Webhosting Large zijn er nog twee andere beschikbaar. Een kleinere website of juist een veel grotere? Maak gebruik van, respectievelijk, <router-link to="/webhosting/webhosting-small/">Webhosting Small</router-link> of <router-link to="/webhosting/webhosting-extra-large/">Webhosting Extra Large</router-link>.</p>
        <h2>Online hosting bestellen</h2>
        <p>Welk pakket voor webhosting u ook kiest, wij zorgen ervoor dat u die eenvoudig online kunt bestellen. Het is voldoende om aan te geven dat u bijvoorbeeld Webhosting Large wilt gebruiken voor de hosting van WordPress. Twijfelt u over de pakketten? Onze technische medewerkers van de klantenservice kunnen u helpen de juiste keuze te maken.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <RandomQuote/>
      </div>
      <WebhostingRightSideBlocks/>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Price from '@/components/Price.vue';
import WebhostingRightSideBlocks from '@/components/WebhostingRightSideBlocks.vue';
import RandomQuote from '@/components/RandomQuote.vue';

export default {
  name: 'WebhostingMedium',
  components: {
    Breadcrumbs,
    Price,
    WebhostingRightSideBlocks,
    RandomQuote,
  },
  setup() {
    const data = inject('data');

    return {
      // store
      data,
    };
  },
}
</script>